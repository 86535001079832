<!-- <label class="form-label" for="functions">Functions</label> -->
<div class="d-flex flex-column">
  <!-- create region button -->
  <button
    tabindex="-1"
    class="btn btn-outline-primary my-1"
    (click)="createRegionBlock()"
  >
    {{ CREATE_REGION | translate }}
  </button>

  <!-- delete region button -->
  <button
    tabindex="-1"
    class="btn btn-outline-danger my-1"
    (click)="deleteRegionBlock()"
  >
    {{ DELETE_REGION | translate }}
  </button>
</div>

<hr />

<form [formGroup]="blockSettingsFormGroup">
  <!-- region name -->
  <div class="mb-3">
    <h5>{{ 'NAME' | translate }}</h5>
    <div class="d-flex flex-column">
      <input
        autocomplete="off"
        id="remove-password-handler"
        type="text"
        class="form-control form-control-sm form-control-dark"
        formControlName="regionName"
      />
    </div>
  </div>

  <hr />

  <app-action-settings
    *ngIf="isInteractiveLayout"
    [action]="action"
    (actionChange)="onActionChange($event)"
    [parentForm]="blockSettingsFormGroup"
  ></app-action-settings>
  <!-- 
    -->
  <hr />

  <h5>Dimensions</h5>

  <!-- width -->
  <div class="mb-3">
    <label class="form-label" for="width">Width</label>
    <input
      autocomplete="off"
      id="remove-password-handler"
      type="number"
      class="form-control form-control-sm form-control-dark"
      formControlName="width"
      (focus)="$event.target.select()"
    />
  </div>

  <!-- height -->
  <div class="mb-3">
    <label class="form-label" for="height">Height</label>
    <input
      autocomplete="off"
      id="remove-password-handler"
      type="number"
      class="form-control form-control-sm form-control-dark"
      formControlName="height"
      (focus)="$event.target.select()"
    />
  </div>
</form>

<!-- fill canvas button -->
<button
  class="d-flex align-items-center btn btn-outline-secondary"
  ngbTooltip="Update region dimensions and position to fill the whole canvas"
  (click)="updateRegionToFillCanvas()"
>
  <i class="ri-fullscreen-line"></i>
  <div class="ms-2">
    {{ 'FILL_CANVAS' | translate }}
  </div>
</button>

<hr />

<form [formGroup]="blockSettingsFormGroup">
  <h5>Position</h5>

  <!-- top -->
  <div class="mb-3">
    <label class="form-label" for="top">Top</label>
    <input
      autocomplete="off"
      id="remove-password-handler"
      type="number"
      class="form-control form-control-sm form-control-dark"
      formControlName="top"
      (focus)="$event.target.select()"
    />
  </div>

  <!-- left -->
  <div class="mb-3">
    <label class="form-label" for="left">Left</label>
    <input
      autocomplete="off"
      id="remove-password-handler"
      type="number"
      class="form-control form-control-sm form-control-dark"
      formControlName="left"
      (focus)="$event.target.select()"
    />
  </div>
</form>

<!-- z-position control-->
<div class="mb-3 d-flex flex-column">
  <label class="form-label" for="zPosition">Z-Position</label>
  <div class="btn-group">
    <!-- send to back -->
    <button
      class="btn btn-outline-secondary"
      ngbTooltip="send to back"
      (click)="onSendToBack()"
    >
      <div style="transform: rotateZ(90deg)">
        <i class="ri-skip-forward-fill"></i>
      </div>
    </button>

    <!-- send backward -->
    <button
      class="btn btn-outline-secondary"
      ngbTooltip="send backward"
      (click)="onSendBackward()"
    >
      <div style="transform: rotateZ(90deg)">
        <i class="ri-play-fill"></i>
      </div>
    </button>

    <!-- bring forward -->
    <button
      class="btn btn-outline-secondary"
      ngbTooltip="bring forward"
      (click)="onBringForward()"
    >
      <div style="transform: rotateZ(-90deg)">
        <i class="ri-play-fill"></i>
      </div>
    </button>

    <!-- bring to front -->
    <button
      class="btn btn-outline-secondary"
      ngbTooltip="bring to front"
      (click)="onBringToFront()"
    >
      <div style="transform: rotateZ(-90deg)">
        <i class="ri-skip-forward-fill"></i>
      </div>
    </button>
  </div>
</div>
