import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LayoutType } from '@desquare/enums';
import { IRegionAction } from '@desquare/interfaces';
import { LayoutEditorService } from '@desquare/services';
import { ZPositionMovement } from '@desquare/utils';
import { SubSink } from 'subsink';
import { v4 as uuidV4 } from 'uuid';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ActionSettingsComponent } from '../action-settings/action-settings.component';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    ActionSettingsComponent,
    NgbTooltip,
    TranslateModule,
  ],
  selector: 'app-layout-region-settings',
  templateUrl: './block-settings.component.html',
  styleUrls: ['./block-settings.component.scss'],
})
export class BlockSettingsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  /** delete this */
  action?: IRegionAction;

  @Input() parentFormGroup?: FormGroup;
  blockSettingsFormGroup!: FormGroup;
  get formControls() {
    return this.blockSettingsFormGroup.controls;
  }
  // selectedRegionBlockName: string = '';
  @Input() isManageMode = false;

  get isInteractiveLayout() {
    return this.layoutEditorService.layoutType === LayoutType.Interactive;
  }

  /* dynamic labels */
  CREATE_REGION = 'CREATE_REGION';
  DELETE_REGION = 'DELETE_REGION';

  constructor(
    private layoutEditorService: LayoutEditorService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initDynamicLabels();
    this.initForm();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initDynamicLabels() {
    if (this.isInteractiveLayout) {
      this.CREATE_REGION = 'CREATE_REGION_INTERACTIVE';
      this.DELETE_REGION = 'DELETE_REGION_INTERACTIVE';
    }
  }

  initForm() {
    const FORM_GROUP_NAME = 'blockSettingsForm';

    this.blockSettingsFormGroup =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        regionName: '',
        width: [0],
        height: [0],
        top: [0],
        left: [0],
        // backgroundColor: '',
        action: {},
      });

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(
        FORM_GROUP_NAME,
        this.blockSettingsFormGroup
      );
    }
  }

  initSubscriptions() {
    // editor to form stream
    this.subs.sink =
      this.layoutEditorService.lastSelectedRegionBlock$.subscribe((value) => {
        // Disable the form if no region block is selected
        if (value == null) {
          // if value === null or value === ''
          // this.formControls.regionName.disable();
          this.blockSettingsFormGroup.disable({ emitEvent: false });

          // this.disableInputFields();
          this.blockSettingsFormGroup.reset();
        } else {
          // this.formControls.regionName.enable();
          this.blockSettingsFormGroup.enable({ emitEvent: false });
          // this.enableInputFields();
        }

        if (value) {
          const { id, name, width, height, top, left } = value;
          let { action } = value;

          if (!action && this.isInteractiveLayout) {
            action = {
              id: uuidV4(),
              type: 'PLAY_VIDEO',
            };
            this.layoutEditorService.setAction(id, action);
          }

          // updating a formGroup sub item  like formGroup.action.target does not fire formGroup.action subscription
          // therefore, considering custom actions with multiple unknown fields is better to
          // detach action editor from any-based formGroup logic and instead treat action as an object value with a specific event
          this.action = action;
          this.blockSettingsFormGroup.patchValue(
            {
              regionName: name,
              width,
              height,
              top,
              left,
              action,
            },
            { emitEvent: false }
          );
        }
      });

    // form to editor stream: name
    this.subs.sink = this.formControls.regionName.valueChanges.subscribe(
      (value) => {
        this.layoutEditorService.updateSelectedRegionBlock({
          regionName: value,
        });
      }
    );

    // form to editor stream: width
    this.subs.sink = this.formControls.width.valueChanges.subscribe((value) => {
      this.layoutEditorService.updateSelectedRegionBlock({
        width: value,
      });
    });

    // form to editor stream: height
    this.subs.sink = this.formControls.height.valueChanges.subscribe(
      (value) => {
        this.layoutEditorService.updateSelectedRegionBlock({
          height: value,
        });
      }
    );

    // form to editor stream: top
    this.subs.sink = this.formControls.top.valueChanges.subscribe((value) => {
      this.layoutEditorService.updateSelectedRegionBlock({
        top: value,
      });
    });

    // form to editor stream: left
    this.subs.sink = this.formControls.left.valueChanges.subscribe((value) => {
      this.layoutEditorService.updateSelectedRegionBlock({
        left: value,
      });
    });

    // form to editor stream: action
    this.subs.sink = this.formControls.action.valueChanges.subscribe(
      (value) => {
        this.layoutEditorService.updateSelectedRegionBlock({
          action: value,
        });
      }
    );
  }

  createRegionBlock() {
    this.layoutEditorService.createRegionBlock();
  }

  deleteRegionBlock() {
    this.layoutEditorService.deleteRegionBlock();
  }

  isSelectedBlockNull() {
    return (
      this.layoutEditorService.lastSelectedRegionBlock$.getValue() === null
    );
  }

  onSendToBack() {
    this.layoutEditorService.updateSelectedRegionBlock({
      zPositionMovement: ZPositionMovement.bottom,
    });
  }
  onSendBackward() {
    this.layoutEditorService.updateSelectedRegionBlock({
      zPositionMovement: ZPositionMovement.down,
    });
  }
  onBringForward() {
    this.layoutEditorService.updateSelectedRegionBlock({
      zPositionMovement: ZPositionMovement.up,
    });
  }
  onBringToFront() {
    this.layoutEditorService.updateSelectedRegionBlock({
      zPositionMovement: ZPositionMovement.top,
    });
  }

  onActionChange(action: IRegionAction) {
    console.log('onActionChange', action);
    this.action = action;
    this.blockSettingsFormGroup.patchValue({ action });
  }

  updateRegionToFillCanvas() {
    const canvas = this.layoutEditorService.canvas$.value;

    if (!canvas || !this.layoutEditorService.lastSelectedRegionBlock$.value)
      return;

    this.layoutEditorService.updateSelectedRegionBlock({
      width: canvas.width,
      height: canvas.height,
      top: 0,
      left: 0,
    });
  }
}
